import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import './css/Fotky.css';
import './css/Image.css';
import dum from './static/pngs/venku.png';
import NextIcon from '@material-ui/icons/NavigateNext';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import TrashIcon from '@material-ui/icons/Delete';
import Heading from './Heading';

import Detail from './Detail';
import { Typography, Button, TextField, Fab } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import PlusIcon from '@material-ui/icons/Add';

export interface Props {
  files: File[];
  addFile: (newFiles: File[]) => void;
  selected?: File;
  selectFile: (file: File | undefined) => void;
  subStep: number;
  details: File[];
  addDetail: (newDetails: File[]) => void;
  selectedDetail?: File;
  selectDetail: (detail: File | undefined) => void;
}

interface File {
  file: any;
  path: string;
  note: string;
  key: number;
}

export default function FotkyComp (props: Props) {
  const {files, addFile, selected, selectFile, subStep} = props;

  const useStyles = makeStyles({
    root: {
      width: '-webkit-fill-available',
      margin: '10px 20px',
    },
    typography: {
      textAlign: 'justify',
      margin: '10px 0 0 0 '
    },
    picture: {
      display: 'flex',
      border: '2px solid #ff9900',
      padding: '20px',
      margin: '10px auto'
    },
    addButton: {
      margin: '10px auto',
    },
    note: {
      maxWidth: '300px',
      width: '100%',
      margin: '0 auto'
    }
  });

  const getHeight = () => {
    let height = 0;
    if (typeof window !== 'undefined') {
      height = window.innerHeight - 375;
    }
    return height;
  }

  const classes = useStyles();
  return (
    <React.Fragment>
      {
        subStep === 1
        ? <Detail 
            files={props.details}
            addFile={props.addDetail}
            selectFile={props.selectDetail}
            selected={props.selectedDetail}
          />
        : <div className="Fotky-wrapper">
            <Heading style={{margin: '0 auto'}} label="Vyfoťte nám poškozený dům"/>
            <div id="image-wrapper-component" style={{flex: '1 1 auto', display:'flex', justifyContent: 'space-between', flexFlow: 'column', width: '-webkit-fill-available', maxWidth: selected === undefined ? '500px' : '800px', margin: '0 auto'}}>
              {
                files.length === 0
                ? <React.Fragment>
                    <Typography className={classes.typography} component="p">
                      Prosíme, vyfoťte poškozený dům ze všech dostupných stran ...
                    </Typography>
                    <div className={classes.picture}>
                      <img style={{maxWidth: '100px', maxHeight: '100px'}} alt='poskozeny dum' src={dum} />
                    </div>
                  </React.Fragment>
                : <React.Fragment>
                    <div style={{height: getHeight(), minHeight: '200px', width: 'stretch', background:'black', position: "relative", margin: 'auto 0'}}>
                      <Fab
                        style={{position: 'absolute', top: '-10px', right: '-10px', width: '50px', height: '50px'}}
                        color="primary"
                        aria-label="Odstranit"
                        onClick={() => {
                          const newFiles = [...files];
                          if (selected) {
                            const index = newFiles.findIndex(item => item.key === selected.key);
                            newFiles.splice(index, 1);
                            selectFile(newFiles.length === 0 ? undefined : (newFiles[index] ? newFiles[index] : newFiles[index - 1]))
                          }
                          addFile(newFiles);
                        }}
                      >
                        <TrashIcon />
                      </Fab>
                      {
                        files.length < 5 &&
                        <Fab
                          style={{position: 'absolute', bottom: '-10px', right: '-10px', width: '50px', height: '50px'}}
                          color="primary"
                          aria-label="Přidat"
                          onClick={() => {
                            const element = document.getElementById('avatar');
                            if (element) {
                              element.click();
                            }
                          }}
                        >
                          <PlusIcon />
                        </Fab>
                      }
                      {
                        selected && files[0] && files[0].key !== selected.key &&
                        <Fab
                          style={{position: 'absolute', top: 'calc(50% - 25px)', left: '-10px', width: '50px', height: '50px'}}
                          color="primary"
                          aria-label="Předchozí"
                          onClick={() => {
                            const index = files.findIndex(item => item.key === selected.key);
                            selectFile(files[index - 1]);
                          }}
                        >
                          <PrevIcon />
                        </Fab>
                      }
                      {
                        selected && files[files.length - 1] && files[files.length - 1].key !== selected.key &&
                        <Fab
                          style={{position: 'absolute', top: 'calc(50% - 25px)', right: '-10px', width: '50px', height: '50px'}}
                          color="primary"
                          aria-label="Další"
                          onClick={() => {
                            const index = files.findIndex(item => item.key === selected.key);
                            selectFile(files[index + 1]);
                          }}
                        >
                          <NextIcon />
                        </Fab>
                      }
                      <img
                        style={{
                          objectFit: 'contain',
                          width: '100%',
                          height: '100%',
                        }}
                        alt='foto'
                        src={selected ? selected.path : undefined}
                      />
                    </div>
                  </React.Fragment>
              }
              <div style={{flex: '0 0 auto', display: 'flex', flexFlow: 'column'}}>
                {
                  selected !== undefined &&
                  <TextField
                    value={files[files.findIndex(item => item.key === selected.key)] ? files[files.findIndex(item => item.key === selected.key)].note : ''}
                    onChange={(e) => {
                      const newFiles = [...files];
                      const index = newFiles.findIndex(item => item.key === selected.key);
                      newFiles[index] = {...files[index], note: e.target.value};
                      addFile(newFiles);
                      selectFile(newFiles[index]);
                    }}
                    label="Poznámka"
                    placeholder="Co je na fotce důlležité?"
                    className={classes.note}
                  />
                }
                {
                  selected === undefined &&
                  <Button
                    className={classes.addButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const element = document.getElementById('avatar');
                      if (element) {
                        element.click();
                      }
                    }}
                  >
                    Přidat fotografii
                    <CameraIcon
                      style={{marginLeft: '10px'}}
                    />
                  </Button>
                }
              </div>
              <input
                onChange={(e: any) => {
                  if (e.target.files[0]) {
                    const newFiles = [...files];
                    let key = 0;
                    newFiles.forEach(item => {
                      if (item.key >= key) {
                        key = item.key + 1;
                      }
                    })
                    newFiles.push({path: URL.createObjectURL(e.target.files[0]), file: e.target.files[0], note: '', key});
                    addFile(newFiles);
                    selectFile({file: e.target.files[0], path: URL.createObjectURL(e.target.files[0]), note: '', key});
                  }
                }}
                hidden
                type="file"
                id="avatar"
                name="picture"
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
      }
    </React.Fragment>
  );
};
