export const resetState = () => {
  return {
    type: 'RESET_STATE'
  };
};

export const setActiveStep = (step: number) => {
	return {
    type: 'SET_ACTIVESTEP',
    value: step
	};
};

export const setDetailStep = (step: number) => {
	return {
    type: 'SET_DETAILSTEP',
    value: step
	};
};

export const setDescription = (desc: string) => {
  return {
    type: 'SET_DESC',
    value: desc
  };
};

export const setIssueDate = (date: string) => {
  return {
    type: 'SET_ISSUE_DATE',
    value: date
  };
}

export const setOccupied = (occupied: string) => {
  return {
    type: 'SET_OCCUPIED',
    value: occupied
  };
};

export const setReconstruction = (reconstructed: string) => {
  return {
    type: 'SET_RECONSTRUCTED',
    value: reconstructed
  };
};

export const setVenec = (venec: string) => {
  return {
    type: 'SET_VENEC',
    value: venec
  };
};

export const setSirka = (sirka: string) => {
  return {
    type: 'SET_SIRKA',
    value: sirka
  };
};

export const setTyp = (typ: string) => {
  return {
    type: 'SET_TYP',
    value: typ
  };
};

export const setPodsklepen = (sklep: string) => {
  return {
    type: 'SET_SKLEP',
    value: sklep
  };
};

export const setPristavba = (pristavba: string) => {
  return {
    type: 'SET_PRISTAVBA',
    value: pristavba
  };
};

export const setHloubka = (hloubka: string) => {
  return {
    type: 'SET_HLOUBKA',
    value: hloubka
  };
};

export const setPodlozi = (podlozi: string) => {
  return {
    type: 'SET_TYP_ZEMINY',
    value: podlozi
  };
};

export const setTrees = (trees: string) => {
  return {
    type: 'SET_TREES',
    value: trees
  };
};

export const setKanal = (kanal: string) => {
  return {
    type: 'SET_KANAL',
    value: kanal
  };
};

export const setSvah = (svah: string) => {
  return {
    type: 'SET_SVAH',
    value: svah
  };
};

export const setVoda = (voda: string) => {
  return {
    type: 'SET_VODA',
    value: voda
  };
};

export const setSvody = (svody: string) => {
  return {
    type: 'SET_SVODY',
    value: svody
  };
};

export const setNastaveni = (nastaveni: {googleApi: string, staticalUrl: string}) => {
  return {
    type: 'SET_SETTINGS',
    value: nastaveni
  }
};
