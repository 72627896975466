import * as React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography, Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import {connect} from 'react-redux';
import { Reducer } from './utils/types';
import { Dispatch } from 'redux';
import { setTrees, setKanal, setVoda, setSvody, setSvah } from './redux/actions';
// import {} from './redux/actions';

interface Props {
  reducer?: Reducer;
  dispatch: Dispatch;
}


export default connect(reducer => reducer)((props: Props) => {
  const useStyles = makeStyles({
    root: {
      flex: '0 0 auto',
      margin: '10px 20px',
    },
    select: {
      textAlign: 'left',
      paddingLeft: '0px'
    },
    typography: {
      textAlign: 'justify',
      margin: '10px 20px 0'
    }
  });

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography className={classes.typography}>Okolí Vaší stavby může být zdrojem mnoha komplikací při probíhající opravě. Prosím, zadejte nám o něm co nejvíce informací ...</Typography>
      <div className="Doplnky-form">

        <FormControl className={classes.root}>
          <InputLabel htmlFor="stromy">Vzrostlé stromy (i pokácené) v okolí</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.stromy : ''}
            onChange={(e: any) => props.dispatch(setTrees(e.target.value))}
            inputProps={{
              name: 'valueTrees',
              id: 'stromy',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="kanal">Problémy s kanalizací</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.kanal : ''}
            onChange={(e: any) => props.dispatch(setKanal(e.target.value))}
            inputProps={{
              name: 'valueKanal',
              id: 'kanal',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="svody">Dešťové svody čisté</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.svody : ''}
            onChange={(e: any) => props.dispatch(setSvody(e.target.value))}
            inputProps={{
              name: 'valueSvody',
              id: 'svody',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="svah">Dům ve svahu</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.svah : ''}
            onChange={(e: any) => props.dispatch(setSvah(e.target.value))}
            inputProps={{
              name: 'valueSvah',
              id: 'svah',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="voda">Blízko vodní plochy/řeky</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.voda : ''}
            onChange={(e: any) => props.dispatch(setVoda(e.target.value))}
            inputProps={{
              name: 'valueVoda',
              id: 'voda',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

      </div>
    </React.Fragment>
  );
});
