import {gql} from 'apollo-boost'

export const qGetSettings = gql`
  query getSettings {
    ziskatNastaveni{
      googleApi
      staticalUrl
    }
  }
`;
