import { Reducer } from '../../src/utils/types';
import moment from 'moment';

const initialState: Reducer = {
  activeStep: 0,
  desc: '',
  date: moment().format('YYYY-MM-DD'),
  occupied: 'Ano',
  reconstructed: 'Ne',
  detailStep: 0,
  venec: '',
  sirkaZdi: '',
  typZdiva: '',
  podsklepen: '',
  pristavba: '',
  hloubka: '',
  typPodlozi: '',
  stromy: '',
  kanal: '',
  svah: '',
  voda: '',
  svody: '',
  googleApi: undefined
};

export default function ReducerFunction (state = initialState, payload: { type: string, value: any }) {
  switch (payload.type) {
    case 'RESET_STATE': return {
      ...initialState
    };

    case 'SET_ACTIVESTEP': return {
      ...state,
      activeStep: payload.value
    };

    case 'SET_DETAILSTEP': return {
      ...state,
      detailStep: payload.value
    };

    case 'SET_DESC': return {
      ...state,
      desc: payload.value
    };

    case 'SET_ISSUE_DATE': return {
      ...state,
      date: payload.value
    };

    case 'SET_OCCUPIED': return {
      ...state,
      occupied: payload.value
    };

    case 'SET_RECONSTRUCTED': return {
      ...state,
      reconstructed: payload.value
    };

    case 'SET_VENEC': return {
      ...state,
      venec: payload.value
    };

    case 'SET_SIRKA': return {
      ...state,
      sirkaZdi: payload.value
    };

    case 'SET_TYP': return {
      ...state,
      typZdiva: payload.value
    };

    case 'SET_SKLEP': return {
      ...state,
      podsklepen: payload.value
    };

    case 'SET_PRISTAVBA': return {
      ...state,
      pristavba: payload.value
    };

    case 'SET_HLOUBKA': return {
      ...state,
      hloubka: payload.value
    };

    case 'SET_TYP_ZEMINY': return {
      ...state,
      typPodlozi: payload.value
    };

    case 'SET_TREES': return {
      ...state,
      stromy: payload.value
    };

    case 'SET_KANAL': return {
      ...state,
      kanal: payload.value
    };

    case 'SET_SVAH': return {
      ...state,
      svah: payload.value
    };

    case 'SET_VODA': return {
      ...state,
      voda: payload.value
    };

    case 'SET_SVODY': return {
      ...state,
      svody: payload.value
    };

    case 'SET_SETTINGS': {
      return {
        ...state,
        googleApi: payload.value ? payload.value.googleApi : '',
        staticalUrl: payload.value ? payload.value.staticalUrl : ''
      }
    }

    default: return state;
  }
}
