import * as React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import {connect} from 'react-redux';
import { Reducer } from './utils/types';
import { Dispatch } from 'redux';
import { setActiveStep } from './redux/actions';

export interface Props {
    reducer?: Reducer;
    dispatch: Dispatch;
};

export default connect(reducer => reducer)((props: Props) => (
  props.reducer
  ? <div className="Stepper-wrapper">

      <div
        className={`Stepper-step-circle ${props.reducer.activeStep === 0 ? 'Stepper-step-active' : ''} ${props.reducer.activeStep > 0 ? 'Stepper-step-done' : ''}`}
        // @ts-ignore
        onClick={() => props.reducer.activeStep > 0 ? props.dispatch(setActiveStep(0)) : null}
      >  
        <div className='Stepper-step-inner'>
          {
            props.reducer.activeStep > 0
            ? <DoneIcon className='Stepper-done-icon' />
            : '1'
          }
        </div>
      </div>

      <div className={`Stepper-divider ${props.reducer.activeStep > 0 ? 'Stepper-divider-done' : ''}`} />

      <div
        className={`Stepper-step-circle ${props.reducer.activeStep === 1 ? 'Stepper-step-active' : ''} ${props.reducer.activeStep > 1 ? 'Stepper-step-done' : ''}`}
        onClick={() => props.reducer!.activeStep > 1 ? props.dispatch(setActiveStep(1)) : null}
      >
        <div className='Stepper-step-inner'>
        {
            props.reducer.activeStep > 1
            ? <DoneIcon className='Stepper-done-icon' />
            : '2'
          }
        </div>
      </div>

      <div className={`Stepper-divider ${props.reducer.activeStep > 1 ? 'Stepper-divider-done' : ''}`} />

      <div
        className={`Stepper-step-circle ${props.reducer.activeStep === 2 ? 'Stepper-step-active' : ''} ${props.reducer.activeStep > 2 ? 'Stepper-step-done' : ''}`}
        onClick={() => props.reducer!.activeStep > 2 ? props.dispatch(setActiveStep(2)) : null}
      >
        <div className='Stepper-step-inner'>
          {
            props.reducer.activeStep > 2
            ? <DoneIcon className='Stepper-done-icon' />
            : '3'
          }
        </div>
      </div>

      <div className={`Stepper-divider ${props.reducer.activeStep > 2 ? 'Stepper-divider-done' : ''}`} />
      
      <div
        className={`Stepper-step-circle ${props.reducer.activeStep === 3 ? 'Stepper-step-active' : ''} ${props.reducer.activeStep > 3 ? 'Stepper-step-done' : ''}`}
        onClick={() => props.reducer!.activeStep > 3 ? props.dispatch(setActiveStep(3)) : null}
      >
        <div className='Stepper-step-inner'>
          {
            props.reducer.activeStep > 3
            ? <DoneIcon className='Stepper-done-icon' />
            : '4'
          }
        </div>
      </div>

      <div className={`Stepper-divider ${props.reducer.activeStep > 3 ? 'Stepper-divider-done' : ''}`} />
      
      <div
        className={`Stepper-step-circle ${props.reducer.activeStep === 4 ? 'Stepper-step-active' : ''} ${props.reducer.activeStep > 4 ? 'Stepper-step-done' : ''}`}
        onClick={() => props.reducer!.activeStep > 4 ? props.dispatch(setActiveStep(4)) : null}
      >
        <div className='Stepper-step-inner'>
          {
            props.reducer.activeStep > 4
            ? <DoneIcon className='Stepper-done-icon' />
            : '5'
          }
        </div>
      </div>

    </div>
  : null
));