import * as React from 'react';
import './css/Heading.css';

export interface Props {
  label: string;
  style?: Object;
};

export default function Heading (props: Props) {
  return (
    <div style={props.style} className="Heading-wrapper">
      <h3 className="Heading-label">{props.label}</h3>
    </div>
  );
};
