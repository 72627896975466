import * as React from 'react';
import './css/Navod.css';
import {Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Heading from './Heading';

import prasklina from './static/pngs/prasklina.png';

export interface Props {
  onClick: (problem: number) => void;
};

const useStyles = makeStyles({
  wrapper: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0px'
  },
  description: {
    flex: '0 1 400px',
    textAlign: 'justify',
  },
});

export default function NavodComp (props: Props) {
  let buttonSize: any = '';
  const classes = useStyles();

  if (typeof window !== 'undefined') {
    buttonSize = window.innerWidth < window.innerHeight ? window.innerWidth / 3 : window.innerHeight / 3;
  }

  return (
    <div id='navod-text-wrapper' className="Navod-wrapper">
      <div className="Navod-content" id="navod-content">
        <Heading label="Co Vás trápí?" />
        <div className="Navod-buttons">
          <div className="Navod-moznost">
            <div
              className="Navod-moznost-picture"
              style={{
                maxHeight: '120px',
                maxWidth: '120px',
                height: buttonSize,
                width: buttonSize,
                borderRadius: buttonSize / 2
              }}
            >
              <img src={prasklina} alt='prasklina' className="Navod-button-obrazek" />
            </div>
          </div>
        </div>
        <div className={classes.wrapper}>
          <Typography className={classes.description}>
            Kliknutím na tlačítko níže Vás v několika jednoduchých krocích provedeme popisem Vašeho problému a odesláním nezávazné poptávky našich služeb.
          </Typography>
        </div>
      </div>
    </div>
  );
};
