import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import './css/index.css';
import mainStore from './redux/store';
import {ApolloClient, InMemoryCache, from} from "apollo-boost";
import {ApolloProvider} from "react-apollo-hooks";
import {createHttpLink} from "apollo-link-http";
import fetch from "isomorphic-fetch";

const store = mainStore();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  fetch,
});

const client = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only"
    }
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
