import * as React from 'react';
import './css/App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import * as actions from './redux/actions';
import { useQuery, useApolloClient } from 'react-apollo-hooks';
import {qGetSettings} from './graphql/be/operations/qGetSettings';
import {getSettings, UrlProNahraniSouboru, UrlProNahraniSouboruVariables, OdeslatPoptavku, OdeslatPoptavkuVariables} from './graphql/be/generated';
import { qUrlProNahraniSouboru } from './graphql/be/operations/qUrlProNahraniSouboru';
import {mOdeslatPoptavku} from './graphql/be/operations/mPoslatPoptavku';
import axios from 'axios';

import Header from './Header';
import {Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography} from '@material-ui/core';
import Stepper from './Stepper';
import Navod from './Navod';
import Fotky from './Fotky';
import Adresa from './Adresa';
import Doplnky from './Doplnky';
import Kontakt from './Kontakt';
import Dekujeme from './Dekujeme';
import Loader from './Loader';
import { Dispatch } from 'redux';
import { Reducer } from './utils/types';
import validator from 'validator';
import moment from 'moment';
import {v4 as uuid} from 'uuid';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff9900',
      light: '#ff9900',
      dark: '#ff9900',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#ff9900',
      light: '#ff9900',
      dark: '#ff9900',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
  },
});

interface State {
  problem: number;
  height?: number;
  selected?: File;
  selectedDetail?: File;
  fotoSubStep: number;
  doplnky: DoplnkyI;
  adresa: string;
  kontakt: KontaktI;
  errorMessage?: string;
  odesilani: boolean;
  init: boolean;
}

interface File {
  file: any;
  path: string;
  note: string;
  key: number;
}

interface DoplnkyI {
  sirkaZdi?: string;
  typZeminy?: string;
  spodniVoda?: string;
  hloubkaZakladu?: string;
  typZdiva?: string;
  poznamka?: string;
}

interface KontaktI {
  jmeno: string;
  firma: string;
  email: string;
  telefon: string;
  souhlas: boolean;
}

interface Props {
  reducer?: Reducer;
  dispatch: Dispatch;
}

function App (props: Props) {
  const client = useApolloClient();
  const INITIAL_STATE: State = {
    problem: 0,
    height: undefined,
    selected: undefined,
    selectedDetail: undefined,
    fotoSubStep: 0,
    doplnky: {},
    adresa: '',
    kontakt: {
      jmeno: '',
      firma: '',
      email: '',
      telefon: '',
      souhlas: false
    },
    errorMessage: undefined,
    odesilani: false,
    init: false
  }
  const [state, setState]: [State, (newState: any) => void] = React.useState(INITIAL_STATE);
  const [files, setFiles] = React.useState<File[]>([]);
  const [details, setDetails] = React.useState<File[]>([]);

  const width = typeof document !== 'undefined' ? window.innerWidth : 0;

  const nastaveniQuery = useQuery<getSettings>(qGetSettings);

  const {dispatch} = props;
  React.useEffect(() => {
    if (!nastaveniQuery.loading && !state.init) {
      // @ts-ignore
      dispatch(actions.setNastaveni(nastaveniQuery.data.ziskatNastaveni));
      setState((actState: State) => ({...actState, init: true}));
    }
  }, [nastaveniQuery, dispatch, state.init]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setState((actState: State) => ({...actState, height: window.innerHeight}));
    }
  }, []);

  function getStepContent() {
    if (props.reducer) {
      switch (props.reducer.activeStep) {
        case 0: return <Navod onClick={(item: number) => {
          // @ts-ignore
          setState({...state, activeStep: props.reducer.activeStep + 1, problem: item});
        }} />;
        case 1: return <Doplnky
          problem={state.problem}
          doplnky={state.doplnky}
          nastavDoplnky={(doplnky: DoplnkyI) => setState({...state, doplnky})}
        />;
        case 2: return <Fotky
          files={files}
          addFile={(newFiles: any) => setFiles(newFiles)}
          selected={state.selected}
          selectFile={(selectedFile: File | undefined) => setState({...state, selected: selectedFile})}
          subStep={state.fotoSubStep}
          details={details}
          addDetail={(newDetails: File[]) => setDetails(newDetails)}
          selectDetail={(selectedDetail: File | undefined) => setState({...state, selectedDetail})}
          selectedDetail={state.selectedDetail}
        />;
        case 3: return <Adresa
          adresa={state.adresa}
          nastavAdresu={(text: string) => setState({...state, adresa: text})}
        />;
        case 4: return <Kontakt
          kontakt={state.kontakt}
          nastavKonakt={(newKontakt: KontaktI) => setState({...state, kontakt: newKontakt})}
          odesilani={state.odesilani}
        />;
        case 5: return <Dekujeme />;
        default: return 'Unknown step';
      }
    }
    return 'Unknown step'
  };

  function validateStep() {
    if (process.env.NODE_ENV === 'production' && props.reducer) {
    // if (true && props.reducer) {
      switch (props.reducer.activeStep) {
        case 1: {
          if (props.reducer.desc === undefined || props.reducer.desc.trim() === '') {
            setState({...state, errorMessage: 'Pole s popisem poškození musí být vyplněno...'})
            return false;
          }
          if (props.reducer.date !== undefined && moment(props.reducer.date).valueOf() > moment().valueOf()) {
            setState({...state, errorMessage: 'Nelze vybrat datum z budoucnosti ...'})
            return false;
          }
          return true;
        }
        case 2: {
          if (state.fotoSubStep === 0) {
            if (files.length > 0) {
              return true;
            } else {
              setState({...state, errorMessage: 'Je potřeba přidat alespoň jednu fotografii objektu...'})
              return false;
            }
          } else {
            if (details.length > 0) {
              return true;
            } else {
              setState({...state, errorMessage: 'Je potřeba přidat alespoň jednu fotografii detailu poškození...'})
              return false;
            }
          }
        }
        case 3: {
          if (state.adresa.trim() !== '') {
            return true;
          } else {
            setState({...state, errorMessage: 'Je potřeba zadat adresu objektu...'})
            return false;
          }
        }
        case 4: {
          if (state.kontakt.souhlas === false) {
            setState({...state, errorMessage: 'Je potřeba souhlasit se zpracováním Vašich údajů...'})
            return false;
          }
          if (
            validator.isEmpty(state.kontakt.jmeno) ||
            state.kontakt.jmeno.split(' ').length < 2
          ) {
            setState({...state, errorMessage: 'Je potřeba zadat správně jméno a příjmení...'})
            return false;
          }
          if (!validator.isEmpty(state.kontakt.email) && !validator.isEmail(state.kontakt.email)) {
            setState({...state, errorMessage: 'Je potřeba zadat správně e-mailovou adresu...'})
            return false;
          }
          if (validator.isEmpty(state.kontakt.telefon) || !validator.isMobilePhone(state.kontakt.telefon)) {
            setState({...state, errorMessage: 'Je potřeba zadat sprváně telefonní číslo...'})
            return false;
          }
          return true;
        }
        default: {
          return true;
        }
      }
    }
    return true;
  }

  return (
    <MuiThemeProvider theme={theme}>
        <div style={{height: state.height, position: 'static'}} className="App">
          <Header />

          <Dialog
            open={state.errorMessage !== undefined}
          >
            <DialogTitle style={{background: 'black', color: '#fff'}}>
              Chyba
            </DialogTitle>
            <DialogContent>
              <Typography component='p'>
                {
                  state.errorMessage || 'Chyba formuláře!'
                }
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  color: '#ff9900',
                  marginRight: '10px'
                }}
                onClick={() => setState({...state, errorMessage: undefined})}
              >
                ok
              </Button>
            </DialogActions>
          </Dialog>

          <div className="App-Content">
            {/* Stepper menu */}
            <Stepper />

            {/* Obsah daného kroku */}
            <div className='App-Stepper-Content'>
              {
                (state.odesilani || nastaveniQuery.loading)
                ? <Loader
                    title={nastaveniQuery.loading ? "Načítám data" : undefined}
                  />
                : getStepContent()
              }
            </div>

            {/* navigační tlačítka */}
            <div style={width > 399 ? {justifyContent: 'center'} : undefined} className='Button-wrapper'>
              {
                props.reducer && props.reducer.activeStep === 0 &&
                <Button
                  disabled={(state.odesilani || nastaveniQuery.loading)}
                  // @ts-ignore
                  onClick={() => props.dispatch(actions.setActiveStep(props.reducer.activeStep + 1))}
                  variant='contained'
                  style={{
                    color: '#fff',
                    background: '#ff9900',
                    padding: '5px 30px',
                    margin: '0 auto'
                  }}
                >
                  Začít
                </Button>
              }
              {
                props.reducer && (props.reducer.activeStep !== 0 && props.reducer.activeStep !== 5) &&
                <Button
                  disabled={(state.odesilani || nastaveniQuery.loading)}
                  onClick={() => {
                    // @ts-ignore
                    if (props.reducer.activeStep === 2 && state.fotoSubStep === 1) {
                      setState({...state, fotoSubStep: 0});
                    } else {
                      // @ts-ignore
                      if (props.reducer.activeStep === 1 && props.reducer.detailStep > 0) {
                        // @ts-ignore
                        props.dispatch(actions.setDetailStep(props.reducer.detailStep - 1));
                      } else {
                        // @ts-ignore
                        props.dispatch(actions.setActiveStep(props.reducer.activeStep - 1));
                      }
                    }
                  }}
                  style={{
                    color: '#ff9900',
                    marginRight: '10px',
                    // marginBottom: props.reducer && props.reducer.activeStep === 4 ? '30px' : undefined
                  }}
                >
                  Zpět
                </Button>
              }
              {
                props.reducer && (props.reducer.activeStep !== 5 && props.reducer.activeStep !== 0) &&
                <Button
                  disabled={(state.odesilani || nastaveniQuery.loading)}
                  onClick={async () => {
                    if (validateStep()) {
                      // @ts-ignore
                      if (props.reducer.activeStep === 2 && state.fotoSubStep === 0) {
                        setState({...state, fotoSubStep: 1});
                      } else {
                        // @ts-ignore
                        if (props.reducer.activeStep === 4) {
                          setState({...state, odesilani: true});
                          try {
                            const photourls = Promise.all(files.map(async file => {
                              const nazevSoboru = `${uuid()}.${file.file.name.split('.')[file.file.name.split('.').length-1]}`;
                              const minioUrl = await client.query<UrlProNahraniSouboru, UrlProNahraniSouboruVariables>({
                                query: qUrlProNahraniSouboru,
                                variables: {
                                  nazev: nazevSoboru
                                }
                              });
                              const newFile = file.file;
                              const type = newFile.type;
                              console.log(`soubor: `, file.file);
                              const config = {
                                headers: {
                                  'content-type': type
                                }
                              };
                              await axios.put(minioUrl.data.urlProNahraniSouboru, newFile, config);
                              return nazevSoboru;
                            }));

                            const detailurls = Promise.all(details.map(async file => {
                              const nazevSoboru = `${uuid()}.${file.file.name.split('.')[file.file.name.split('.').length-1]}`;
                              const minioUrl = await client.query<UrlProNahraniSouboru, UrlProNahraniSouboruVariables>({
                                query: qUrlProNahraniSouboru,
                                variables: {
                                  nazev: nazevSoboru
                                }
                              });
                              const newFile = file.file;
                              const type = newFile.type;
                              console.log(`soubor: `, file.file);
                              const config = {
                                headers: {
                                  'content-type': type
                                }
                              };
                              await axios.put(minioUrl.data.urlProNahraniSouboru, newFile, config);
                              return nazevSoboru;
                            }));

                            const photos = await photourls;
                            const photoNotes = files.map(item => item.note);
                            const detailphotos = await detailurls;
                            const detailNotes = details.map(item => item.note);

                            if (props.reducer) {
                              client.mutate<OdeslatPoptavku, OdeslatPoptavkuVariables>({
                                mutation: mOdeslatPoptavku,
                                variables: {
                                  poptavka: {
                                    desc: props.reducer.desc,
                                    date: props.reducer.date || '',
                                    occupied: props.reducer.occupied,
                                    reconstructed: props.reducer.reconstructed,
                                    venec: props.reducer.venec,
                                    sirkaZdi: props.reducer.sirkaZdi,
                                    typZdiva: props.reducer.typZdiva,
                                    podsklepen: props.reducer.podsklepen,
                                    pristavba: props.reducer.pristavba,
                                    hloubka: props.reducer.hloubka,
                                    typPodlozi: props.reducer.typPodlozi,
                                    stromy: props.reducer.stromy,
                                    kanal: props.reducer.kanal,
                                    svah: props.reducer.svah,
                                    voda: props.reducer.voda,
                                    svody: props.reducer.svody,
                                    adresa: state.adresa,
                                    jmeno: state.kontakt.jmeno,
                                    firma: state.kontakt.firma,
                                    email: state.kontakt.email,
                                    telefon: state.kontakt.telefon,
                                    nazvyPriloh: [...photos, ...detailphotos],
                                    poznamky: [...photoNotes, ...detailNotes]
                                  }
                                }
                              })
                              .then(result => {
                                // @ts-ignore
                                props.dispatch(actions.setActiveStep(props.reducer.activeStep + 1));
                                setState({...state, odesilani: false});
                              })
                              .catch(err => {
                                throw new Error('Chyba při odesílání emailu');
                              });
                            }
                          } catch(err) {
                            console.error(err.message);
                            console.error(err);
                            setState({...state, odesilani: false, errorMessage: 'Nepodařilo se odeslat poptávku. Zkuste to, prosím, později...'});
                          }
                        } else {
                          // @ts-ignore
                          if (props.reducer.activeStep === 1 && props.reducer.detailStep < 3) {
                            // @ts-ignore
                            props.dispatch(actions.setDetailStep(props.reducer.detailStep + 1));
                          } else {
                            // @ts-ignore
                            props.dispatch(actions.setActiveStep(props.reducer.activeStep + 1))
                          }
                        }
                      }
                    }
                  }}
                  variant='contained'
                  style={{
                    color: '#fff',
                    background: '#ff9900',
                    // marginBottom: props.reducer && props.reducer.activeStep === 4 ? '30px' : undefined
                  }}
                >
                  {
                    props.reducer && props.reducer.activeStep === 4
                    ? 'Odeslat'
                    : 'Pokračovat'
                  }
                </Button>
              }
              {
                props.reducer && props.reducer.activeStep === 5 &&
                <Button
                  disabled={(state.odesilani || nastaveniQuery.loading)}
                  onClick={() => {
                    if (process.env.NODE_ENV==='production') {
                      window.location.assign(props.reducer?.staticalUrl || '')
                    } else {
                      setState(INITIAL_STATE);
                      setFiles([]);
                      setDetails([]);
                      props.dispatch(actions.resetState());
                    }
                  }}
                  variant='contained'
                  style={{
                    color: '#fff',
                    background: '#ff9900',
                    margin: '0 auto'
                  }}
                >
                  Domů
                </Button>
              }
            </div>
          </div>
        </div>
    </MuiThemeProvider>
  );
};

export default connect(reducer => reducer)(App);
