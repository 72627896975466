import * as React from 'react';
import './css/Doplnky.css';
import Obecne from './Obecne';
import Zdivo from './Zdivo';
import Zaklady from './Zaklady';
import Okoli from './Okoli';

import Heading from './Heading';
import { Reducer } from './utils/types';
import { Dispatch } from 'redux';
import {connect} from 'react-redux';

export interface Props {
  problem: number;
  doplnky: Doplnky;
  nastavDoplnky: (doplnky: Doplnky) => void;
  reducer?: Reducer;
  dispatch: Dispatch;
}

interface Doplnky {
  sirkaZdi?: string;
  typZeminy?: string;
  spodniVoda?: string;
  hloubkaZakladu?: string;
  typZdiva?: string;
  poznamka?: string;
}



export default connect(reducer => reducer)((props: Props) => {

  return (
    <div className="Doplnky-wrapper">
      <div className="Doplnky-content">
        <Heading label="Řekněte nám o tom více ..." />
        {
          props.reducer && props.reducer.detailStep === 0 &&
          <Obecne />
        }
        {
          props.reducer && props.reducer.detailStep === 1 &&
          <Zdivo />
        }
        {
          props.reducer && props.reducer.detailStep === 2 &&
          <Zaklady />
        }
        {
          props.reducer && props.reducer.detailStep === 3 &&
          <Okoli />
        }
        </div>
    </div>
  );
});
