import * as React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography, TextField, Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import {connect} from 'react-redux';
import { Reducer } from './utils/types';
import { Dispatch } from 'redux';
import {setDescription, setIssueDate, setOccupied, setReconstruction} from './redux/actions';
import moment from 'moment';

interface Props {
  reducer?: Reducer;
  dispatch: Dispatch;
}


export default connect(reducer => reducer)((props: Props) => {
  const useStyles = makeStyles({
    root: {
      flex: '0 0 auto',
      margin: '10px 20px'
    },
    select: {
      textAlign: 'left',
      paddingLeft: '0px'
    },
    typography: {
      textAlign: 'justify',
      margin: '10px 20px 0'
    }
  });

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography className={classes.typography}>Začněme stručným popisem Vašeho problému. Technické detaily vyřešíme v následujících krocích ...</Typography>
      <div className="Doplnky-form">
        <TextField
          id="standard-multiline-flexible"
          label={"Stručný popis problému"}
          placeholder={"Popište nám stručně Váš problém ..."}
          multiline
          rows={4}
          value={props.reducer ? props.reducer.desc : ''}
          onChange={(e: any) => props.dispatch(setDescription(e.target.value))}
          className={classes.root}
          margin="normal"
        />

        <TextField
          id="standard-multiline-flexible"
          label={"Od kdy pozorujete trhliny"}
          type='date'
          // @ts-ignore
          value={props.reducer ? props.reducer.date : undefined}
          error={props.reducer && moment(props.reducer.date).valueOf() > moment().valueOf()}
          onChange={(e: any) => {
            props.dispatch(setIssueDate(e.target.value))
          }}
          className={classes.root}
          margin="normal"
        />
        
        <FormControl className={classes.root}>
          <InputLabel htmlFor="obydleni">Dům je trvale obydlen</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.occupied : ''}
            onChange={(e: any) => props.dispatch(setOccupied(e.target.value))}
            inputProps={{
              name: 'typObydleni',
              id: 'obydleni',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="rekonstrukce">Po celkové rekonstrukci</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.reconstructed : ''}
            onChange={(e: any) => props.dispatch(setReconstruction(e.target.value))}
            inputProps={{
              name: 'poRekonstrukci',
              id: 'rekonstrukce',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
});
