import * as React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography, Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import {connect} from 'react-redux';
import { Reducer } from './utils/types';
import { Dispatch } from 'redux';
import {setVenec, setSirka, setTyp} from './redux/actions';

interface Props {
  reducer?: Reducer;
  dispatch: Dispatch;
}


export default connect(reducer => reducer)((props: Props) => {
  const useStyles = makeStyles({
    root: {
      flex: '0 0 auto',
      margin: '10px 20px',
    },
    select: {
      textAlign: 'left',
      paddingLeft: '0px'
    },
    typography: {
      textAlign: 'justify',
      margin: '10px 20px 0'
    }
  });

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography className={classes.typography}>Druh zdiva ovlivňuje celou stavbu i naše postupy při její opravě. Zadejte proto, prosím, co nejvíce informací ve formulář níže ...</Typography>
      <div className="Doplnky-form">

        <FormControl className={classes.root}>
          <InputLabel htmlFor="venec">Budova má věnec</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.venec : ''}
            onChange={(e: any) => props.dispatch(setVenec(e.target.value))}
            inputProps={{
              name: 'maVenec',
              id: 'venec',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="sirka">Šířka zdi</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.sirkaZdi : ''}
            onChange={(e: any) => props.dispatch(setSirka(e.target.value))}
            inputProps={{
              name: 'sirkaZdi',
              id: 'sirka',
            }}
          >
            <MenuItem value={'20'}>20 cm</MenuItem>
            <MenuItem value={'30'}>30 cm</MenuItem>
            <MenuItem value={'40'}>40 cm</MenuItem>
            <MenuItem value={'50'}>50 cm</MenuItem>
            <MenuItem value={'Více'}>Více</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="typZdiva">Typ zdiva</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.typZdiva : ''}
            onChange={(e: any) => props.dispatch(setTyp(e.target.value))}
            inputProps={{
              name: 'typZdiva',
              id: 'typZdiva',
            }}
          >
            <MenuItem value={'cihla'}>Cihla</MenuItem>
            <MenuItem value={'kámen'}>Kámen</MenuItem>
            <MenuItem value={'smíšené'}>Smíšené</MenuItem>
            <MenuItem value={'beton'}>Beton</MenuItem>
          </Select>
        </FormControl>

      </div>
    </React.Fragment>
  );
});
