import * as React from 'react';
import './css/Adresa.css';
import { makeStyles } from '@material-ui/styles';
import Heading from './Heading';
import {TextField} from '@material-ui/core';
import Map from './Map';
import {connect} from 'react-redux';
import { Reducer } from './utils/types';

export interface Props {
  adresa: string;
  nastavAdresu: (text: string) => void;
  reducer?: Reducer;
}

const Adresa = (props: Props) => {

  const useStyles = makeStyles({
    root: {
      width: '-webkit-fill-available',
      margin: '10px 20px',
    },
  });

  const classes = useStyles();

  return (
    <div className="Adresa-wrapper">
      <Heading style={{margin: '0 auto'}} label="Kde se objekt nachází?"/>
      <div className="Adresa-input-wrapper">
        <TextField
          id="standard-multiline-flexible"
          label={"Adresa objektu"}
          value={props.adresa}
          onChange={(e: any) => props.nastavAdresu(e.target.value)}
          className={classes.root}
          margin="normal"
        />
      </div>

      <div style={typeof window !== 'undefined' && window.innerWidth > 400 ? {height: '50vh'} : undefined } className="Adresa-map">
        <Map
          query={props.adresa}
          googleApiKey={(props.reducer && props.reducer.googleApi) ? props.reducer.googleApi : ''}
        />
      </div>
      
    </div>
  )
};

export default connect(reducer => reducer)(Adresa);
