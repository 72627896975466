import * as React from 'react';
import './css/Loader.css';
import LoopIcon from '@material-ui/icons/Loop';

export default function Loader (props: {title?: string}) {
  return (
    <div className={'loader-background'}>
      <div className={'loader-icon-wrapper'}>
        <LoopIcon style={{width: '50px', height: '50px'}} />
      </div>
      <div className={'loader-text'}>
        {
          props.title || 'Odesílám formulář'
        }
      </div>
    </div>
  );
};
