import * as React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography, Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';
import {connect} from 'react-redux';
import { Reducer } from './utils/types';
import { Dispatch } from 'redux';
import { setPodsklepen, setPristavba, setHloubka, setPodlozi } from './redux/actions';

interface Props {
  reducer?: Reducer;
  dispatch: Dispatch;
}


export default connect(reducer => reducer)((props: Props) => {
  const useStyles = makeStyles({
    root: {
      flex: '0 0 auto',
      margin: '10px 20px',
    },
    select: {
      textAlign: 'left',
      paddingLeft: '0px'
    },
    typography: {
      textAlign: 'justify',
      margin: '10px 20px 0'
    }
  });

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography className={classes.typography}>Špatné základy Vaší stavby mohou být zdrojem mnoha potíží. Prosím, zadejte nám o nich co nejvíce informací ...</Typography>
      <div className="Doplnky-form">

        <FormControl className={classes.root}>
          <InputLabel htmlFor="podkslepeni">Dům je podsklepen</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.podsklepen : ''}
            onChange={(e: any) => props.dispatch(setPodsklepen(e.target.value))}
            inputProps={{
              name: 'typSklepa',
              id: 'podsklepeni',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
            <MenuItem value={'Částečně'}>Částečně</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="pristavba">Dům má přístavbu</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.pristavba : ''}
            onChange={(e: any) => props.dispatch(setPristavba(e.target.value))}
            inputProps={{
              name: 'sPristavbou',
              id: 'pristavba',
            }}
          >
            <MenuItem value={'Ano'}>Ano</MenuItem>
            <MenuItem value={'Ne'}>Ne</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="hloubka">Hloubka základů</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.hloubka : ''}
            onChange={(e: any) => props.dispatch(setHloubka(e.target.value))}
            inputProps={{
              name: 'hloubkaZakladu',
              id: 'hloubka',
            }}
          >
            <MenuItem value={'40'}>40 cm</MenuItem>
            <MenuItem value={'60'}>60 cm</MenuItem>
            <MenuItem value={'80'}>80 cm</MenuItem>
            <MenuItem value={'100'}>100 cm</MenuItem>
            <MenuItem value={'Více'}>Více</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.root}>
          <InputLabel htmlFor="podlozi">Typ půdy</InputLabel>
          <Select
            classes={{
              select: classes.select,
            }}
            value={props.reducer ? props.reducer.typPodlozi : ''}
            onChange={(e: any) => props.dispatch(setPodlozi(e.target.value))}
            inputProps={{
              name: 'druhPodlozi',
              id: 'podlozi',
            }}
          >
            <MenuItem value={'jíl'}>Jíl</MenuItem>
            <MenuItem value={'Jílovito-písčitá'}>Jílovito-písčitá</MenuItem>
            <MenuItem value={'Písek'}>Písek</MenuItem>
            <MenuItem value={'Navážka'}>Navážka</MenuItem>
            <MenuItem value={'Skalní podloží'}>Skalní podloží</MenuItem>
          </Select>
        </FormControl>

      </div>
    </React.Fragment>
  );
});
