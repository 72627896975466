import * as React from 'react';
import './css/Dekujeme.css';

export default function Dekujeme () {
  return (
    <div className="Dekujeme-wrapper">
      <div className="Dekujeme-content">
        <div className="Dekujeme-from-left">
          Děkujeme za Vaši důvěru,
        </div>
        <div className="Dekujeme-from-right">
          budeme Vás brzy kontaktovat.
        </div>
      </div>
    </div>
  );
}