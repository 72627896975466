import * as React from 'react';
import {TextField, Checkbox, Typography, Dialog, DialogContent, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import './css/Kontakt.css';
import Heading from './Heading';

export interface Props {
  kontakt: Kontakt;
  nastavKonakt: (novyKontakt: Kontakt) => void;
  odesilani?: boolean;
}

interface Kontakt {
  jmeno: string;
  firma: string;
  email: string;
  telefon: string;
  souhlas: boolean;
}

export default function KontaktComp (props: Props) {

  const useStyles = makeStyles({
    root: {
      width: '-webkit-fill-available',
      margin: '10px 20px',
      minHeight: '40px'
    },
  });

  const classes = useStyles();

  return (
    <div className="Kontakt-wrapper">
      <Dialog open={props.odesilani ? true : false}>
        <DialogContent style={{
          flex: '1 1 auto',
          padding: '8px 24px',
          minWidth: '100px',
          minHeight: '100px',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center'
        }}>
          <CircularProgress style={{width: '40px', height: '40px', margin: '0 auto 10px'}} />
          <Typography style={{margin: '0 auto', color: '#ff9900'}}>
            Odesílám
          </Typography>
        </DialogContent>
      </Dialog>
      <Heading style={{marginBottom: '10px'}} label="Zanechte nám kontakt ..."/>
      <TextField
        id="standard-multiline-flexible"
        label={"Firma, společnost"}
        value={props.kontakt.firma}
        onChange={(e: any) => props.nastavKonakt({...props.kontakt, firma: e.target.value})}
        className={classes.root}
        margin="normal"
        autoComplete="organization"
      />
      <TextField
        id="standard-multiline-flexible"
        label={"Jméno a příjmení"}
        value={props.kontakt.jmeno}
        onChange={(e: any) => props.nastavKonakt({...props.kontakt, jmeno: e.target.value})}
        className={classes.root}
        margin="normal"
        autoComplete="name"
      />
      <TextField
        id="standard-multiline-flexible"
        label={"Emailová adresa"}
        value={props.kontakt.email}
        onChange={(e: any) => props.nastavKonakt({...props.kontakt, email: e.target.value})}
        className={classes.root}
        margin="normal"
        autoComplete="email"
      />
      <TextField
        id="standard-multiline-flexible"
        label={"Telefon"}
        value={props.kontakt.telefon}
        onChange={(e: any) => props.nastavKonakt({...props.kontakt, telefon: e.target.value})}
        className={classes.root}
        margin="normal"
        autoComplete="tel"
      />
      <div style={{display: 'flex', justifyContent: 'flex-start', margin: '10px 20px 0 20px'}}>
        <Checkbox style={{margin: 'auto'}} color="primary" checked={props.kontakt.souhlas} onChange={() => props.nastavKonakt({...props.kontakt, souhlas: !props.kontakt.souhlas})} />
        <Typography component="p" style={{textAlign: "justify", fontSize: '0.85rem', color: '#0000008c', margin: '20px 0 20px 30px'}}>
          Souhlasím se zpracováním osobních údajů firmou Statical&nbsp;s.r.o. za účelem kontaktování mé osoby s nabídkou realizace služeb.
        </Typography>
      </div>
    </div>
  );
};
