import * as React from 'react';
import logo from './static/pngs/logo-statical.png';

export default function Header () {
    return (
        <header className="App-header">
            <div className="App-header-wrapper">
                <img src={logo} className="App-logo" alt="logo" />
            </div>
        </header>
    );
};
