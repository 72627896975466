import {compose, withProps} from 'recompose';
import {withScriptjs, withGoogleMap, GoogleMap} from "react-google-maps";
import * as React from "react";

interface MyProps {
  onClick?: (e: google.maps.MouseEvent) => void;
  query: string;
  googleApiKey: string;
}

const Mapa = compose<MyProps, MyProps>(
  withProps((foo: MyProps) => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${foo.googleApiKey}&libraries=geometry,drawing,places`,
    loadingElement: <div style={{height: `100%`}}/>,
    containerElement: <div style={{width: `inherit`, height: `inherit`}}/>,
    mapElement: <div id='mapa' style={{height: `100%`}}/>
  })),
  withScriptjs,
  withGoogleMap
)((props: MyProps) => {
  const center = new google.maps.LatLng(50.0835494, 14.4341414);
  // @ts-ignore
  const mapa = new google.maps.Map(document.getElementById('mapa'), {
    center,
    zoom: 7,
    scaleControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false
  });
  const request = {
    query: props.query,
    fields: ['geometry'],
  };
  if (mapa) {
    const service = new google.maps.places.PlacesService(mapa);
    service.findPlaceFromQuery(request, (results: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        mapa.setCenter(results[0].geometry.location);
        mapa.setZoom(17);
        new google.maps.Marker({
          map: mapa,
          position: results[0].geometry.location
        });
      }
    });
  };

  return (<GoogleMap
    onClick={props.onClick}
  />
)});

export default Mapa;
